import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {useInputData} from "./Hooks";
import {FsxResponse, Input, LambdaError} from "../models";
import {API} from "aws-amplify";
import PricingTable from "./PricingTable";
import LoadingScreen from "./LoadingScreen";

function PricingWrapper(props: { onInputDecoded: (arg0: Input) => void }) {

    const [fsxResponse, setFsxResponse] = useState<FsxResponse>();
    const {input, decode} = useInputData();
    const {token} = useParams<{ token: string }>();
    const [errorMessage, setErrorMessage] = useState<LambdaError>();

    useEffect(() => {
        if (!token) {
            return;
        }
        try {
            props.onInputDecoded(decode(token));
            setFsxResponse(undefined);
        } catch (err) {
            console.error(err);
        }
// eslint-disable-next-line
    }, [token]);

    useEffect(() => {
        function requestAwsPricing(input: Input): Promise<FsxResponse> {
            const request = {
                body: convertPercentageInputToFractions(input)
            };
            return API.post('awstcoapi', "/cost/fsx", request);
        }

        if (!input) {
            return;
        }

        requestAwsPricing(input as Input).then(fsxResponse => {
            setFsxResponse(fsxResponse);
            setErrorMessage(undefined);
        }).catch(ex => {
            setFsxResponse(undefined);
            setErrorMessage({
                code: ex.response.data.error.code,
                message: ex.response.data.error.message,
            });
        });
    }, [input]);

    function convertPercentageInputToFractions(input: Input): Input {
        return {
            region: input.region,
            storage: {
                data: input.storage.data,
                ssd: input.storage.ssd / 100,
                throughput: input.storage.throughput,
                multiAz: input.storage.multiAz,
                efficiency: input.storage.efficiency / 100,
                utilization: input.storage.utilization / 100
            },
            onPrem: {
                hardwareDepreciation: input.onPrem.hardwareDepreciation,
                storageGrowth: input.onPrem.storageGrowth / 100
            }
        };
    }

    return (
        <>
            {fsxResponse && (
                <PricingTable fsxResponse={fsxResponse}/>
            )}

            {!fsxResponse && (
                <LoadingScreen error={errorMessage || undefined}/>
            )}
        </>
    );
}

export default PricingWrapper;
