import {CurrencyUnit, FsxResponse, Pricing} from "../models";
import {
    Badge,
    Button,
    Card,
    Collapse,
    FormControl,
    InputGroup,
    ListGroup,
    OverlayTrigger,
    Popover,
    Toast
} from "react-bootstrap";
import React, {useEffect, useRef, useState} from "react";
import {COLORS} from "./Constants";
import {
    CategoryScale,
    Chart,
    ChartConfiguration,
    ChartItem,
    Legend,
    LinearScale,
    LineController,
    LineElement,
    PointElement,
    Tooltip
} from "chart.js";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faCopy, faQuestionCircle, faShare} from "@fortawesome/free-solid-svg-icons";

import './PricingTable.scss';

function PricingTable(props: { fsxResponse: FsxResponse }) {
    const [openBreakdown, setOpenBreakdown] = useState(false);
    const chartRef = useRef<HTMLCanvasElement>(null);

    useEffect(() => {
        if (chartRef === null) {
            return;
        }

        const cumulativeSpendData = props.fsxResponse.graphs.find(g => g.id === 'cumulativeSpend');

        const cumulativeSpendChart = cumulativeSpendData ? {
            type: 'line',
            data: {
                labels: cumulativeSpendData.data.map((item, index) => index + 1),
                datasets: [{
                    label: 'Amazon FSx for NetApp ONTAP',
                    data: cumulativeSpendData.data.map((item) => Math.round(item.fsxGa)),
                    borderColor: COLORS.orange,
                }, {
                    label: 'On-premises',
                    data: cumulativeSpendData.data.map((item) => Math.round(item.onPrem)),
                    borderColor: COLORS.blue,
                }]
            },
            options: {
                plugins: {
                    tooltip: {
                        callbacks: {
                            title(tooltipItems): string | string[] {
                                return `Month #${tooltipItems[0].label}`;
                            },
                            label: function (context) {
                                return `${context.dataset.label}: US$${context.parsed.y.toLocaleString()}`;
                            }
                        }
                    }
                },
                interaction: {
                    intersect: false,
                    mode: 'index',
                },
                scales: {
                    x: {
                        ticks: {
                            callback: function (value, index, values) {
                                return index % 10 === 0 ? `Month #${value}` : '';
                            }
                        }
                    },
                    y: {
                        beginAtZero: true,
                        ticks: {
                            // Include a dollar sign in the ticks
                            callback: function (value, index, values) {
                                return `US$${value.toLocaleString()}`;
                            }
                        }
                    }
                }
            }
        } as ChartConfiguration<'line'> : undefined;

        if (cumulativeSpendChart) {
            const myChart = new Chart(chartRef.current as ChartItem, cumulativeSpendChart);

            return () => {
                myChart.destroy();
            }
        }
    }, [chartRef, props.fsxResponse]);

    Chart.register(LineController, CategoryScale, LinearScale, PointElement, LineElement, Legend, Tooltip);


    const href = window.location.href;
    const [copiedToClipboard, setCopiedToClipboard] = useState<boolean>(false);

    function copyToClipboard() {
        setCopiedToClipboard(true);
        return navigator.clipboard.writeText(window.location.href);
    }

    function convertCurrency(currencyUnit: CurrencyUnit) {
        if (currencyUnit === 'USD') {
            return '$';
        }
    }

    const popoverShareLink = (
        <Popover id="popover-basic">
            <Popover.Content className="mb-2">
                <p className="text-muted">Share the results by copy the URL and giving it to the person(s) of your
                    choosing.</p>
                <InputGroup>
                    <InputGroup.Prepend>
                        <Button variant="primary"
                                onClick={() => copyToClipboard()}>
                            <FontAwesomeIcon icon={faCopy}/>
                        </Button>
                    </InputGroup.Prepend>
                    <FormControl defaultValue={href} readOnly/>
                </InputGroup>
                <Toast show={copiedToClipboard}
                       onClose={() => setCopiedToClipboard(false)}
                       className="bg-white border-0 shadow-none p-0"
                       delay={3000}
                       autohide>
                    <Toast.Body className="p-0">
                        <small className="text-success">
                            <FontAwesomeIcon icon={faCheck} className="mr-1"/>
                            Copied to clipboard
                        </small>
                    </Toast.Body>
                </Toast>
            </Popover.Content>
        </Popover>
    );

    const awsCost = props.fsxResponse.pricing.find(p => p.id === 'awsFsx');
    const onPremCost = props.fsxResponse.pricing.find(p => p.id === 'onPrem');
    let totalSavings = 0;
    let totalSavingPercentage = 0;
    if (awsCost && onPremCost) {
        totalSavings = Math.round((onPremCost.cost - awsCost.cost));
        totalSavingPercentage = Math.round(100 * (1 - (awsCost.cost / onPremCost.cost)));
    }

    const popoverAWSTotalCost = TooltipContent("AWS costs assume a multi-availability zone configuration where " +
        "AWS synchronously replicates data across availability zones for high availability and durability.");
    const popoverOnPremTotalCost = TooltipContent(
        "On-premises costs assume a 40% reduction of IDC costs " +
        "on average discounts, and a second data center for high availability and durability.",
        "Source: IDC Pricing Evaluator Service: Storage Comparison Tool v5.7.257"
    );

    function TooltipContent(content: string, boldContent?: string) {
        return (
            <Popover id="popover-basic">
                <Popover.Content>
                    {content} <strong>{boldContent}</strong>
                </Popover.Content>
            </Popover>
        );
    }

    function renderPrice(currency: CurrencyUnit, cost: number, unit: string) {
        return (
            <span className="price">
                <sup className="price-currency">{convertCurrency(currency)}</sup>
                <span className="price-cost" >
                        {Math.round(cost).toLocaleString()}
                    </span>
                <small className="price-unit">/{unit}</small>
            </span>
        )
    }

    function renderPricing(pricing: Pricing) {
        let help;
        if (pricing.id === 'awsFsx') {
            help = popoverAWSTotalCost;
        }
        if (pricing.id === 'onPrem') {
            help = popoverOnPremTotalCost;
        }

        return (
            <>
                <p className="font-weight-light text-nowrap">Total cost {pricing.label}
                    {help && (
                        <OverlayTrigger
                            trigger={["hover", "focus"]}
                            placement="top"
                            overlay={help}>
                            <FontAwesomeIcon className="tooltip-icon ml-1" icon={faQuestionCircle}/>
                        </OverlayTrigger>
                    )}
                </p>
                <div className="text-nowrap text-40 mb-3">
                    {renderPrice(pricing.currency, pricing.cost, pricing.unit)}
                </div>

                {pricing.sections.map(pricingSection => (
                    <div className="d-flex flex-row border-top p-2 pt-3 pb-3" key={pricingSection.id}>
                        <div className="flex-shrink-0 text-30 w-150">
                            {renderPrice(pricingSection.currency, pricingSection.cost, pricingSection.unit)}
                        </div>
                        <div>
                            <h6>{pricingSection.label}</h6>
                            {pricingSection.breakdown.map((breakdown, index) => (
                                <div key={index}>
                                    <small className="text-muted font-italic d-inline d-block">
                                        {breakdown.label}: {convertCurrency(breakdown.currency)}{breakdown.cost.toLocaleString()} {breakdown.currency ? 'per ' : ''}{breakdown.unit}
                                    </small>
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </>
        );
    }

    return (
        <>
            <Card bg={"dark"} text={"white"} className="shadow">
                <Card.Body>
                    <Card.Text className="d-flex flex-wrap justify-content-end align-items-center mb-2">
                        <span className="font-weight-lighter text-uppercase flex-grow-1">Total saving</span>
                        <OverlayTrigger
                            trigger={["click"]}
                            placement="left"
                            overlay={popoverShareLink}
                            rootClose>
                            <Button variant={"link"} className="text-white mr-3"><FontAwesomeIcon icon={faShare}/> Share</Button>
                        </OverlayTrigger>
                        <Button variant={"warning"} href="https://pages.awscloud.com/fsxontap.html">Ask an AWS expert</Button>
                    </Card.Text>
                    <Card.Text className="d-flex flex-wrap align-items-center">
                        <span className="text-nowrap text-60">
                            {renderPrice(props.fsxResponse.pricing[1].currency, totalSavings, props.fsxResponse.pricing[1].unit)}
                        </span>
                        <Badge pill variant={totalSavingPercentage >= 0 ? "success" : "danger"} className="ml-2">
                            {totalSavingPercentage.toLocaleString()}%
                        </Badge>
                        <Button variant={"link"} className="text-secondary"
                                onClick={() => setOpenBreakdown(!openBreakdown)}>
                            {openBreakdown ? 'Hide' : 'See'} breakdown
                        </Button>
                    </Card.Text>
                </Card.Body>
            </Card>

            <Collapse in={openBreakdown} className="mt-3">
                <ListGroup className="shadow">
                    <ListGroup.Item variant={"info"}>
                        <small className="text-muted font-italic">
                            There is a potential total {totalSavingPercentage > 0 ? "saving" : "additional cost"} of <Badge
                                variant={totalSavingPercentage >= 0 ? "success" : "danger"} pill>
                                {Math.abs(totalSavingPercentage).toLocaleString()}%
                            </Badge> by provisioning Amazon FSx for NetApp ONTAP storage. For more information on the breakdown by item, see the table below.
                        </small>
                    </ListGroup.Item>

                    <ListGroup.Item>
                        <div className="d-flex flex-lg-nowrap flex-wrap justify-content-between">
                            {props.fsxResponse.pricing.map(pricing => (
                                <div key={pricing.id} className={`flex-grow-1 p-3 ${pricing.id === 'awsFsx' ? 'order-0' : 'order-1'} ${pricing.id === 'onPrem' ? 'bg-light' : ''}`}>
                                    {renderPricing(pricing)}
                                </div>
                            ))}
                        </div>

                    </ListGroup.Item>
                </ListGroup>
            </Collapse>

            <Card className="mt-3 shadow">
                <Card.Body>
                    <Card.Title className="mb-5">Cumulative spend over time ($)</Card.Title>
                    <canvas ref={chartRef} width="400" height="300"/>
                </Card.Body>
            </Card>
        </>
    );
}

export default PricingTable;