import React from "react";
import {Col, Image, Row} from "react-bootstrap";
import BackgroundImage from "./resources/images/AWS_Pattern_Blueprint-Blocks-A_Light-BG_Blue.png";

function OnBoarding() {
    return (
        <>
            <Row>
                <Col xs={12}>
                    <div className="bg-onboarding-header d-flex">
                        <div className="">
                            <h1 className="display-5">Amazon FSx for NetApp ONTAP Pricing Calculator</h1>
                            <p className="lead">Calculate your potential savings by moving Windows and Linux file-based workloads to AWS.</p>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row className="mt-2">
                <Col xs={6}>
                    <p className="lead">It's simple, tell us:</p>
                    <ol className="lead">
                        <li>What you are looking for</li>
                        <li>Your current setup</li>
                    </ol>
                </Col>
                <Col xs={6}>
                    <Image className="img-fluid" src={BackgroundImage} alt=""/>
                </Col>
            </Row>
        </>
    )
}

export default OnBoarding;