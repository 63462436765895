import React, {useCallback, useEffect} from 'react';
import Amplify from 'aws-amplify';
import Container from 'react-bootstrap/Container';
import InputForm from "./InputForm";
import {Input} from "../models";
import {Col, Navbar, Row} from "react-bootstrap";
import OnBoarding from "./OnBoarding";
import {useInputData} from "./Hooks";
import {Link, Route, Switch, useHistory} from 'react-router-dom';
import PricingWrapper from "./PricingWrapper";
import {ReactComponent as AWSLogo} from './resources/images/AWS_logo_RGB_REV.svg';
import DynamicConfig from "./Config"
import "./App.scss";

const AppConfig = DynamicConfig.Config

Amplify.configure({
    API: {
        endpoints: [
            {
                name: "awstcoapi",
                endpoint: AppConfig.APIDomain
            }
        ]
    }
});

function App() {

    useEffect(() => {
        if ('fonts' in document) {
            Promise.all([
                document.fonts.load('700 1em AmazonEmber'),
                document.fonts.load('italic 1em AmazonEmber'),
                document.fonts.load('italic 1em AmazonEmber'),
                document.fonts.load('italic 700 1em AmazonEmber')
            ]).then(_ => {
                document.documentElement.classList.add('fonts-loaded')
            });
        }
    }, [])

    const initialInput: Input = {
        region: '',
        storage: {
            data: 100,
            ssd: 20,
            throughput: 512,
            multiAz: true,
            efficiency: 50,
            utilization: 70
        },
        onPrem: {
            hardwareDepreciation: 36,
            storageGrowth: 20
        }
    };

    let history = useHistory();
    const {input, setInput, encode} = useInputData(initialInput);


    const onInputDecoded = useCallback((decodedInput: Input) => {
        setInput(decodedInput);
    }, [setInput]);

    const onFormValidation = useCallback((submittedInput: Input) => {
        history.push("/" + encode(submittedInput));
    }, [encode, history]);

    return (
        <>
            <div className="apply-font">
                <Navbar bg="dark" variant="dark" expand="lg" sticky="top">
                    <Navbar.Brand as={Link} to="/">
                        <AWSLogo width="50" height="30" className="align-self-end ml-5 mr-5"/>
                        Amazon FSx for NetApp ONTAP Pricing Calculator
                    </Navbar.Brand>
                </Navbar>
                <Container fluid className="app">
                    <Row>

                        <Col sm={12} md={6} lg={5}>
                            <InputForm input={input || initialInput} onInputDecoded={onInputDecoded}
                                       onSubmit={onFormValidation}/>
                        </Col>

                        <Switch>
                            <Route path="/:token" exact>
                                <Col className="p-3" sm={12} md={6} lg={7}>
                                    <PricingWrapper onInputDecoded={onInputDecoded}/>
                                </Col>
                            </Route>

                            <Route path="/" exact>
                                <Col className="p-3 order-first order-md-last" sm={12} md={6} lg={7}>
                                    <OnBoarding/>
                                </Col>
                            </Route>
                        </Switch>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default App;
